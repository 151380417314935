export default class Analytics {

  static trackClick(category) {
    if ( self.hasGoogleAnalytics() ) {
      gtag("event", "click", {
        event_category: category,
      });
    }
  }

  static trackCustomerRegistration() {
    if ( self.hasGoogleAnalytics() ) {
      gtag("event", "customer_registered");
    }
  }

  static trackPurchase(transactionId, price, currency, items) {
    if ( self.hasGoogleAnalytics() ) {
      gtag("event", "purchase", {
        transaction_id: transactionId,
        value: price,
        currency: currency,
        items: items.map(i => ({
          item_id: i.id,
          item_name: i.name,
          item_category: i.category,
          price: i.price,
          quantity: i.quantity,
        }))
      })
    }
  }

  static trackNewParkingRequest(submissionId, carPark, customerType) {
    if ( self.hasGoogleAnalytics() ) {
      gtag("event", "new_parking_request", {
        parking_request_submission_id: submissionId,
        car_park: carPark,
        customer_type: customerType,
      })
    }

    if ( self.hasFacebookPixel() ) {
      fbq("trackCustom", "new_parking_request", {
        parking_request_submission_id: submissionId,
        car_park: carPark,
        customer_type: customerType,
      });
    }
  }

  static trackOfferClaim(id, description) {
    if ( self.hasGoogleAnalytics() ) {
      gtag("event", "offer_claimed", {
        offer_title: `${description} (#${id})`,
      })
    }
  }

  static hasGoogleAnalytics() {
    return ( typeof gtag === "function" );
  }

  static hasFacebookPixel() {
    return ( typeof fbq === "function" );
  }
}

const self = Analytics;
window.Analytics = Analytics;
