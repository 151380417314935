import Vue from 'vue/dist/vue.esm.js';
import '../components/carWashPreBookingSlots.vue'

import PremiumBioPromotePopup from '../webapp/premium_bio_promote_popup';
import CouponValidator from '../webapp/coupon_validator';
import MapManager from '../webapp/home/map_manager';

import Analytics from '../webapp/analytics';

document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById("car-wash-pre-booking")) {
    new Vue({
      el: '#car-wash-pre-booking'
    })
  }

  new PremiumBioPromotePopup();
  new CouponValidator();
})

window.MapManager = MapManager;
